<template></template>

<script>
export default {
  name: "StudentAssociations",
  title: "Asociaciones de estudiantes | GE ITR",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>
